import React from 'react'

function FooterCopyright() {
  return (
    <div className=' text-center pt-3 pb-3 ' style={{color:'#ffffff'}}>
      Copyright © 2025 Aayush.
    </div>
  )
}

export default FooterCopyright;
